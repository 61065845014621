import * as React from "react";
import { closeDialog } from "bernie-core";
import { useLocation, useHistory } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { EGDSLayoutFlexItem, EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";
import { WindowWithChatbot, ClientChatbotStore } from "src/stores/chatbotStore/ClientChatbotStore";
import { EGDSIcon } from "@egds/react-core/icons";
import { EGDSText } from "@egds/react-core/text";
import { sendAnalytics } from "./NotificationQueryUtils";
import { Link, Action } from "../types";

export interface DialogActionProps {
  chatbot?: ClientChatbotStore;
  link?: Link;
  analyticsCallback?: (rfrr: string, linkName: string | null) => void;
}

export const DialogAction: React.FunctionComponent<DialogActionProps> = inject("chatbot")(
  observer((props: DialogActionProps) => {
    const { link, analyticsCallback, chatbot } = props;
    const chatBotId = "openChatbot";
    const [initedChatClient, setInitedChatClient] = React.useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
      (async () => {
        setInitedChatClient(
          chatbot !== undefined && (await chatbot.isVirtualAgentInitialized(window as WindowWithChatbot))
        );
      })();
    }, []);
    const useChatbot = link?.id === chatBotId && initedChatClient;
    const actionHref = useChatbot ? "#" : link?.uri?.value;
    /* istanbul ignore next */
    const onChatbotClick = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (analyticsCallback) {
        sendAnalytics(link?.actions as Action[], analyticsCallback);
      }
      closeDialog({ history, location });
      if (useChatbot && chatbot) {
        chatbot.openChatWidget();
      }
    };

    /* istanbul ignore next */
    const onLinkClick = (e: React.MouseEvent<HTMLElement>) => {
      if (analyticsCallback) {
        sendAnalytics(link?.actions as Action[], analyticsCallback);
      }
    };

    return (
      <EGDSLayoutFlexItem minWidth="full_width">
        <EGDSCard border>
          <EGDSSpacing padding="three">
            <EGDSCardContentSection>
              <EGDSLayoutFlex justifyContent="space-between" alignItems="center" className="cardContentContainer">
                <EGDSLayoutFlexItem>
                  <div>
                    <EGDSText weight="medium">{link?.text}</EGDSText>
                  </div>
                </EGDSLayoutFlexItem>
                <EGDSLayoutFlexItem>
                  <EGDSIcon name="chevron_right" />
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </EGDSCardContentSection>
          </EGDSSpacing>
          <EGDSCardLink>
            {useChatbot ? (
              <a onClick={onChatbotClick} href={actionHref}>
                {link?.text}
              </a>
            ) : (
              <a onClick={onLinkClick} href={actionHref}>
                {link?.text}
              </a>
            )}
          </EGDSCardLink>
        </EGDSCard>
      </EGDSLayoutFlexItem>
    );
  })
);
