import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { EGDSSheet, EGDSSheetContent, EGDSSheetTransition } from "@egds/react-core/sheet";
import { EGDSToolbar } from "@egds/react-core/toolbar";
import { EGDSHeading } from "@egds/react-core/text";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { DialogAction } from "./DialogAction";
import { getLineOfBusiness, getFunnelLocation } from "./NotificationQueryUtils";
import { useQuery } from "@apollo/client";
import { BlossomNotificationDocument, BlossomNotificationQueryVariables } from "__generated__/typedefs";
import { ClientChatbotStore } from "src/stores/chatbotStore/ClientChatbotStore";
import { IBexApiFields } from "@shared-ui/bex-api-context";

export interface NotificationDialogProps {
  inputs: {
    context: IBexApiFields["context"];
    lineOfBusiness: string;
    funnelLocation: string;
  };
  chatbot?: ClientChatbotStore;
  analyticsCallback?: (rfrr: string, linkName: string | null) => void;
}

export const NotificationDialog: React.FunctionComponent<NotificationDialogProps> = (
  props: NotificationDialogProps
) => {
  const [isDialogOpen, dialogActions, NotificationDialogComponent] = useDialog("covid-19-dialog");
  const triggerRef = React.createRef<HTMLElement>();
  const variables: BlossomNotificationQueryVariables = {
    context: props.inputs.context,
    notificationLocation: "POPUP",
    lineOfBusiness: getLineOfBusiness(props.inputs.lineOfBusiness),
    funnelLocation: getFunnelLocation(props.inputs.funnelLocation),
  };
  const { data, error, loading } = useQuery(BlossomNotificationDocument, { variables });

  if (error || loading || !data || !data?.notification || !data?.notification?.inlineNotification) {
    return null;
  }

  // eslint-disable-next-line
  const { title, links } = data?.notification?.inlineNotification || ({} as Notification);

  const heading = title?.items[0].text;

  return (
    <EGDSSheetTransition isVisible={isDialogOpen}>
      <NotificationDialogComponent>
        <EGDSSheet isVisible={isDialogOpen} triggerRef={triggerRef} type="full">
          <EGDSToolbar
            key="EGDSToolbar"
            navigationContent={{
              onClick: dialogActions.closeDialog as any,
            }}
          />
          <EGDSSheetContent key="covid-credit-container">
            <EGDSSpacing padding={{ blockstart: "six" }}>
              <div key="cta-dialog-heading">
                <EGDSHeading tag="h2" size={3}>
                  {heading}
                </EGDSHeading>
              </div>
            </EGDSSpacing>
            <EGDSSpacing padding={{ block: "six" }}>
              <EGDSLayoutFlex space="two" wrap="wrap">
                <>
                  {links?.map((link, index) => {
                    return (
                      <DialogAction
                        key={`customer-notification-action-link-${index}`}
                        analyticsCallback={props.analyticsCallback}
                        link={link}
                      />
                    );
                  })}
                </>
              </EGDSLayoutFlex>
            </EGDSSpacing>
          </EGDSSheetContent>
        </EGDSSheet>
      </NotificationDialogComponent>
    </EGDSSheetTransition>
  );
};
